import PhoneInput from "react-phone-number-input";
import React from "react";
import {E164Number} from "libphonenumber-js";
import {useTranslation} from "react-i18next";

export default function CorrespondentBlock({number, index, setContacts, contacts}: {
    number: number,
    index: number,
    setContacts: any,
    contacts: any
}) {

    const {t} = useTranslation();
    const setNumber = (value: any) => {
        let tmp = [...contacts];
        tmp[index].number = value;
        setContacts(tmp);
    }

    const removeBlock = () => {
        if(contacts.length === 1){
            return;
        }

        let tmp = [...contacts];
        tmp.splice(index, 1);
        setContacts(tmp);
    }

    return (
        <div className='position-relative mb-5'>
            <div className='position-absolute top-0 end-0 pe-2'>
                <i
                    className="fa-solid fa-circle-xmark text-danger fs-2 pointer-event"
                    onClick={removeBlock}
                ></i>
            </div>
            <h5>Contact Nº{number}</h5>
            <div className='input-group'>
                <span className="input-group-text mb-4" id="basic-addon1">
                            <i
                                className="fa-solid fa-user"></i></span>
                <input placeholder={t('form-elements.last_first')}
                       type='text'
                       className='form-control mb-4'
                       value={contacts[index].name}
                       onChange={(event => {
                           let tmp = [...contacts];
                           tmp[index].name = event.target.value;
                           setContacts(tmp);
                       })}
                />
            </div>
            <div className='input-group'>
                <span className="input-group-text mb-4" id="basic-addon1">
                            <i className="fa-solid fa-envelope"></i>
                        </span>
                <input placeholder='email'
                       type='email'
                       className='form-control mb-4'
                       value={contacts[index].email}
                       onChange={(event => {
                           let tmp = [...contacts];
                           tmp[index].email = event.target.value;
                           setContacts(tmp);
                       })}
                />
            </div>
            <PhoneInput
                international={false}
                defaultCountry="FR"
                countries={["FR"]}
                country="FR"
                className='form-control'
                placeholder={t('form-elements.number')}
                value={contacts[index].number}
                onChange={value => setNumber(value)}
            />
        </div>
    )
}