import logo from "../assets/logo-blanc-1.png";
import React, {useEffect} from "react";
import {toast} from "react-toastify";
import {useNavigate, useSearchParams} from "react-router-dom";

export default function UserSetup({setSteps}: { setSteps: any }) {
    const [key, setKey] = React.useState<string>("");
    const [params] = useSearchParams();
    const navigate = useNavigate()

    const handleClick = () => {
        if (key === '') {
            toast.error('Clé vide', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return;
        }

        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({id: key})
        };

        fetch(`${process.env.REACT_APP_API_URL}/users/check-id`, requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.success === true) {
                    localStorage.setItem('user_key', key);
                    localStorage.setItem('name', data.user.name);
                    localStorage.setItem('number', String(data.user.number));
                    setSteps(1);
                } else {
                    toast.error('Clé invalide', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            })
            .catch((e) => toast.error('Clé invalide', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            }))
    }

    useEffect(() => {
        const tmpKey = localStorage.getItem('user_key');
        if(tmpKey !== null){
            const requestOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({id: tmpKey})
            };

            fetch(`${process.env.REACT_APP_API_URL}/users/check-id`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    if (data.success === true) {
                        const user = localStorage.getItem('user');
                        if(user !== null) {
                            navigate('/')
                        }else{
                            setSteps(1)
                        }
                    }
                })
                .catch((e) => {
                })
        }

        const key = params.get('key');
        if(key !== null){
            const requestOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({id: key})
            };

            fetch(`${process.env.REACT_APP_API_URL}/users/check-id`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    if (data.success === true) {
                        const user = localStorage.getItem('user');
                        if(user !== null) {
                            navigate('/')
                        }else{
                            setSteps(1)
                        }
                    }
                })
                .catch((e) => {
                })
        }

    }, [])

    return (
        <div className='container p-4 col-12 col-md-4 ms-auto me-auto'>
            <div className='text-center'>
                <img
                    src={logo}
                    className="img-fluid mt-3 mb-3"
                    style={{
                        width: '45%'
                    }} alt=''/>
                <div className='text-center mt-3'>
                    <h3 className='dark-blue'>Insère la cléf unique d'identification</h3>
                    <h4 className='text-blue'>reçue par SMS</h4>
                    <input placeholder='xxxx - xxxx - xxxx- xxxx'
                           type='text'
                           className='form-control mb-3 my-5'
                           value={key}
                           onChange={(e) => setKey(e.target.value)}
                    />
                    <button className='btn btn-outline-dark bg-dark-blue w-100 text-white mt-5'
                            onClick={handleClick}>
                        Valider
                    </button>
                </div>
            </div>
        </div>
    )
}