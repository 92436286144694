import logo from "../assets/logo-blanc-1.png";
import React, {useEffect} from "react";
import {useAddToHomescreenPrompt} from "../hooks/InstallPrompt";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function InstallFirst() {
    const {t} = useTranslation();
    const isInStandaloneMode = () => window.matchMedia('(display-mode: standalone)').matches
    const navigate = useNavigate();
    const isIos = () => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod|mac/.test(userAgent);
    }
    const [prompt, promptToInstall] = useAddToHomescreenPrompt();
    const installAndContinue = (e: React.MouseEvent) => {
        if (!isInStandaloneMode() && !isIos()) {
            promptToInstall();
            return;
        }

        // @ts-ignore
        document.getElementById('installButton').innerHTML = 'Installation terminée.\n' +
            'Fermes ce navigateur.'

        //navigate('/');
    }

    useEffect(() => {
        if(isInStandaloneMode()){
            navigate('/');
        }else{
            localStorage.clear();
        }
    }, []);

    return (
        <div>
            <div className='container p-4 col-12 col-md-4 ms-auto me-auto'>
                <div className='text-center'>
                    <img
                        src={logo}
                        className="img-fluid mt-3 mb-3"
                        style={{
                            width: '45%'
                        }} alt=''/>
                </div>
                <div className='text-center dark-blue'>
                    <h3>{t('installation.title')}</h3>
                </div>
                <div>
                    <div className='card border-0 shadow-sm'>
                        <div className='card-body'>
                            <strong className='text-blue'><i>{t('installation.on-android')}</i></strong>
                            <ul className='mb-0'>
                                <li>
                                    {t('installation.step1')}
                                </li>
                            </ul>
                            {!isInStandaloneMode() && (
                                <div className='text-center mt-3' id='installButton'>
                                    <button className='btn btn-outline-dark bg-dark-blue text-white w-100'
                                            onClick={e => installAndContinue(e)}>
                                        {t('installation.install')}
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='card border-0 shadow-sm mt-3'>
                        <div className='card-body'>
                            <strong className='text-blue'><i>{t('installation.on-ios')}</i></strong>
                            <ul className='mb-0'>
                                <li>
                                    <span className='text-blue'>{t('installation.if-safari')}</span>,
                                    {t('installation.ios1')}
                                </li>
                                <li>
                                    {t('installation.ios2')}
                                </li>
                            </ul>
                            <strong className='text-blue'>{t('installation.close')}</strong>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}