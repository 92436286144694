import {loadStripe} from "@stripe/stripe-js";
import {
    CardElement,
    Elements,
    EmbeddedCheckout,
    EmbeddedCheckoutProvider,
    useElements,
    useStripe
} from "@stripe/react-stripe-js";
import React, {useCallback, useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";

const stripePromise = loadStripe('pk_test_2dWuPsNznAvG6c034IgmNLjn');

const Checkout = () => {
    const {product} = useParams()
    const fetchClientSecret = useCallback(() => {
        // Create a Checkout Session
        return fetch(`${process.env.REACT_APP_API_URL}/checkout/${product}/${localStorage.getItem('user_key')}`)
            .then((res) => res.json())
            .then((data) => data.client_secret);
    }, []);

    const options = {fetchClientSecret};

    return (
        <div id="checkout">
            <EmbeddedCheckoutProvider
                stripe={stripePromise}
                options={options}
            >
                <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
            <div className='container text-center mt-2'>
                <Link to='/' className='btn btn-danger text-center mt-3'>Annuler</Link>
            </div>
        </div>
    )
}

export default Checkout;