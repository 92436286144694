import logo from "../assets/logo-blanc-1.png";
import React, {useState} from "react";
import CorrespondentBlock from "./CorrespondentBlock";
import {toast} from "react-toastify";
import {isValidPhoneNumber} from "libphonenumber-js";
import {Contact} from "../models/Contact";
import {Link, useNavigate} from "react-router-dom";
import {validateEmail} from "../helpers/utilities";
import {Trans, useTranslation} from "react-i18next";

export default function CreateCorrespondents({setSteps, first = true}: { setSteps: any, first: boolean }) {

    const {t} = useTranslation();
    const getContactsFromLocalStorage = (): Contact[] | null => {
        const savedContacts = localStorage.getItem('correspondents');
        if (savedContacts) {
            return JSON.parse(savedContacts) as Contact[];
        }
        return null;
    };
    const [contacts, setContacts] = useState<Contact[]>(() => {
        return getContactsFromLocalStorage() || [
            {
                name: '',
                number: '',
                email: ''
            },
        ]
    });
    const navigate = useNavigate();

    const validateCorrespondents = () => {
        let save = true;
        contacts.forEach((value: any, index) => {
            if (value.name === '') {
                toast.error(`Le nom du contact Nº${index + 1} est vide`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });

                save = false;
                return;
            }

            if (value.email === '') {
                toast.error(`L'email du contact Nº${index + 1} est vide`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });

                save = false;
                return;
            } else {
                if (!validateEmail(value.email)) {
                    toast.error(`L'email du contact Nº${index + 1} est invalide`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });

                    save = false;
                    return;
                }
            }

            if (!isValidPhoneNumber(value.number as string)) {
                toast.error(`Le numéro du contact Nº${index + 1} est invalide`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });

                save = false
                return;
            }
        });

        if (save) {
            localStorage.setItem('correspondents', JSON.stringify(contacts));
            const user = JSON.parse(localStorage.getItem('user') || '[]');
            const user_key = localStorage.getItem('user_key');
            if (Object.keys(user).length > 0) {
                contacts.forEach((v: any) => {
                    const request = {
                        method: 'POST',
                        headers: {'Content-Type': 'application/json'},
                        body: JSON.stringify({
                            email: v.email,
                            user_id: user_key,
                            name: v.name
                        })
                    };
                    fetch(`${process.env.REACT_APP_API_URL}/emails/correspondents`, request)
                })
            }

            localStorage.setItem('first_launch', 'no');
            navigate('/');
        }
    }

    const addContact = () => {
        let tmp = [...contacts];
        tmp.push({
            name: '',
            number: '',
            email: ''
        });
        setContacts(tmp);
    }

    return (
        <div className={`container ${first ? 'p-4' : ''} col-12 col-md-4 ms-auto me-auto`}>
            <div className='text-center'>
                <img
                    src={logo}
                    className="img-fluid mt-3 mb-3"
                    style={{
                        width: '45%'
                    }} alt=''/>
            </div>
            <div className='text-center mb-5 mt-5'>
                <h3>
                    <Trans i18nKey={'form-elements.assign-contact'}></Trans>
                </h3>
                <h6 className='text-blue'>
                    <Trans i18nKey={'form-elements.informational-email'}></Trans>
                </h6>
            </div>
            {contacts.map((v: object, i: number) => (
                <CorrespondentBlock number={i + 1} index={i} setContacts={setContacts} contacts={contacts} key={i}/>
            ))}
            <div className=''>
                {contacts.length < 3 &&
                    <div className='d-flex justify-content-center mt-5'>
                        <button className='btn btn-outline-dark w-100' onClick={addContact}>
                            {t('buttons.add-correspondent')}
                        </button>
                    </div>}
            </div>
            {first && <div className='text-center mt-3'>
                <button className='btn btn-primary bg-dark-blue text-white w-100'
                        onClick={validateCorrespondents}
                >
                    {t('buttons.validate')}
                </button>
            </div>}
            {!first &&
                <div className='text-center mt-5'>
                    <div className='row'>
                        <div className='col-6'>
                            <Link to='/' className='btn btn-outline-dark w-100'>Fermer</Link>
                        </div>
                        <div className='col-6'>
                            <button className='btn btn-primary bg-dark-blue text-white w-100'
                                    onClick={validateCorrespondents}
                            >
                                {t('buttons.validate')}
                            </button>
                        </div>
                    </div>
                </div>}
        </div>
    )
}