import React, {ChangeEvent, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {t} from "i18next";
import PhoneInput from "react-phone-number-input";
import {E164Number} from "libphonenumber-js";
import {Form} from "react-bootstrap";
import {toast} from "react-toastify";
import {loadStripe} from "@stripe/stripe-js";

const stripePromise = loadStripe('pk_test_2dWuPsNznAvG6c034IgmNLjn');
const MyAccount = () => {
    const navigate = useNavigate()
    const [user] = useState<any | undefined>(
        JSON.parse(localStorage.getItem('user') || '[]')
    );
    const [track, setTrack] = useState(parseInt(localStorage.getItem('tracking') as string) || 0)
    const [alarmCount, setAlarmCount] = useState(0)
    const [password, setPassword] = useState('');
    const [confirm, setConfirm] = useState('');
    const [isPasswordVisible, setPasswordVisible] = useState(false);
    const [isPasswordVisible2, setPasswordVisible2] = useState(false);
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    }

    const togglePasswordVisibility = () => {
        setPasswordVisible(!isPasswordVisible);
    };

    const togglePasswordVisibility2 = () => {
        setPasswordVisible2(!isPasswordVisible2);
    };

    const handleCheckout = (product: number) => {
        navigate(`/checkout/${product}`)
    }

    const setTracking = (e: ChangeEvent) => {
        const key = e.target as HTMLInputElement
        if (key.checked) {
            setTrack(1)
        } else {
            setTrack(0)
        }

        localStorage.setItem('tracking', key.checked ? '1' : '0')

        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                user_id: localStorage.getItem('user_key'),
                tracking: key.checked ? 1 : 0
            })
        };

        fetch(`${process.env.REACT_APP_API_URL}/users/set-tracking`, requestOptions)
            .then(response => response.json())
            .then(data => {
            })
            .catch((e) => {
            })
    }

    const handleSaveNewPassword = () => {
        if (password == '') {
            navigate('/')
            return;
        } else {
            if (password !== confirm) {
                toast.error('Le mot de passe n\'est pas identique', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                return;
            } else {
                let tmp = user
                tmp.password = password
                localStorage.setItem('user', JSON.stringify(tmp));
                navigate('/')
            }
        }


    }

    useEffect(() => {
        if (Object.keys(user).length === 0) {
            navigate('/install');
            return;
        }

        fetch(`${process.env.REACT_APP_API_URL}/alarms/user/${localStorage.getItem('user_key')}`)
            .then(response => response.json())
            .then(data => {
                setAlarmCount(data.count)
            })
            .catch(() => {
                navigate('/install');
            })

    }, []);
    return (
        <div className='container col-12 col-md-4 ms-auto me-auto h-100'>
            <h3 className='mt-5 mb-3'>Mon compte</h3>
            <div className='input-group'>
                <span className="input-group-text mb-4" id="basic-addon1">
                            <i
                                className="fa-solid fa-user"></i></span>
                <input placeholder={t('form-elements.last_first')}
                       type='text'
                       className='form-control mb-4'
                       value={user.name}
                />
            </div>
            <div className='input-group'>
                <span className="input-group-text mb-4" id="basic-addon1">
                            <i className="fa-solid fa-envelope"></i>
                        </span>
                <input placeholder='Email'
                       type='email'
                       className='form-control mb-4'
                       value={user.email!}
                />
            </div>
            <PhoneInput
                international={false}
                defaultCountry="FR"
                countries={["FR"]}
                country="FR"
                className='form-control'
                placeholder={t('form-elements.number')}
                value={user.number} onChange={function (value?: E164Number | undefined): void {
                throw new Error("Function not implemented.");
            }}/>
            <hr/>
            <h4 className='mt-3'>Changer votre mot de passe</h4>
            <div className="input-group mb-4">
                <input placeholder='Mot de passe'
                       type={isPasswordVisible ? 'text' : 'password'}
                       className='form-control'
                       value={password}
                       onChange={(event) => setPassword(event.target.value)}
                />
                <span className="input-group-text" onClick={togglePasswordVisibility}
                      style={{cursor: 'pointer'}}>
                            {isPasswordVisible ? <i className='fa fa-eye'></i> :
                                <i className='fa fa-eye-slash'></i>}
                          </span>
            </div>
            <div className="input-group mb-4">
                <input placeholder='Confirmation de mot de passe'
                       type={isPasswordVisible2 ? 'text' : 'password'}
                       className='form-control'
                       value={confirm}
                       onChange={(event) => setConfirm(event.target.value)}
                />
                <span className="input-group-text" onClick={togglePasswordVisibility2}
                      style={{cursor: 'pointer'}}>
                            {isPasswordVisible2 ? <i className='fa fa-eye'></i> :
                                <i className='fa fa-eye-slash'></i>}
                          </span>
            </div>
            <hr/>
            <h4 className='mt-3'>Suivi de la géolocalisation GPS en temps réel (tracking)</h4>
            <div className='mt-3'>
                <div className='row'>
                    <div className='col-6 fs-4'>
                        Activer
                    </div>
                    <div className='col-6'>
                        <Form.Check
                            className='float-end'
                            type="switch"
                            id="custom-switch"
                            checked={track === 1}
                            onChange={setTracking}
                            style={{
                                transform: "scale(1.8)"
                            }}
                        />
                    </div>
                </div>
            </div>
            <hr/>
            <h4 className='mt-3'>Solde SMS</h4>
            <h4>
                Le {new Date().toLocaleString('fr-FR', options)} : <span className='text-blue'>{alarmCount}</span>
            </h4>
            <hr/>
            <h4 className='my-3'>Recharger mon compte SMS</h4>
            <div className='row text-center'>
                <div className='col-6'>
                    <button className='btn btn-info' onClick={e => handleCheckout(1)}>Pack 3 SMS</button>
                </div>
                <div className='col-6'>
                    <button className='btn btn-info' onClick={e => handleCheckout(2)}>Pack 10 SMS</button>
                </div>
            </div>
            <hr/>
            <div className='text-center mb-5'>
                <div className='row'>
                    <div className='col-6'>
                        <Link to='/' className='btn btn-outline-dark w-100'>Fermer</Link>
                    </div>
                    <div className='col-6'>
                        <button className='btn btn-primary bg-dark-blue text-white w-100' onClick={handleSaveNewPassword}>Valider</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyAccount;